<template>
  <section class="ourTeam">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <div class="container">
      <el-tabs :tab-position="tabPosition" class="tab" v-model="editableTabsValue">
        <el-tab-pane class="ourTeam_my" name="ourTeam_my" label="我们的团队" :disabled="isDisabled"></el-tab-pane>
        <el-tab-pane class="ourTeam_team" name="ourTeam_team" label="我们的团队和信念" :lazy="lazy">
          <section class="article">
            <h4 class="title">我们的团队和信念</h4>
            <section v-for="(item, index) in data1" :key="index" class="ourTeam_content">
              <h5>{{ item.title }}</h5>
              <section v-for="(val, ind) in item.content" :key="ind">
                <h6 v-if="val.tit">{{ val.tit }}</h6>
                <p v-if="val.con">{{ val.con }}</p>
                <img v-if="val.url" :src="val.url" :width="val.wid">
                <el-table v-if="val.table" :data="val.table.data" stripe style="width: 100%">
                  <el-table-column prop="people" v-for="(item, i) in val.table.header" :key="i" :label="item.label" :width="item.width" :align="item.align">
                    <template slot-scope="scope">
                      <span v-if="item.label != ''">{{scope.row[i]}}</span>
                      <el-progress v-else :text-inside="true" :stroke-width="20" :percentage="scope.row[i]"></el-progress>
                    </template>
                  </el-table-column>
                </el-table>
              </section>
            </section>
          </section>
        </el-tab-pane>
        <el-tab-pane class="ourTeam_join" name="ourTeam_join" label="加入旋荣" :lazy="lazy">
          <section class="article">
            <!-- <h4 class="title">加入旋荣</h4> -->
            <section v-for="(item, index) in data2" :key="index" class="ourTeam_content">
              <h5>{{ item.title }}</h5>
              <section v-for="(val, ind) in item.content" :key="ind">
                <h6 v-if="val.tit">{{ val.tit }}</h6>
                <p v-if="val.con">{{ val.con }}</p>
                <img v-if="val.url" :src="val.url" :width="val.wid">
              </section>
            </section>
          </section>
        </el-tab-pane>
        <el-tab-pane class="ourTeam_grow" name="ourTeam_grow" label="职业规划和发展" :lazy="lazy">
          <section class="article">
            <h4 class="title">职业规划和发展</h4>
            <section v-for="(item, index) in data3" :key="index" class="ourTeam_content">
              <h5>{{ item.title }}</h5>
              <section v-for="(val, ind) in item.content" :key="ind">
                <h6 v-if="val.tit">{{ val.tit }}</h6>
                <p v-if="val.con">{{ val.con }}</p>
                <img v-if="val.url" :src="val.url" :width="val.wid">
              </section>
            </section>
          </section>
        </el-tab-pane>
        <el-tab-pane class="ourTeam_train" name="ourTeam_train" label="培训" :lazy="lazy">
          <section class="article">
            <h4 class="title">培训</h4>
            <section v-for="(item, index) in data4" :key="index" class="ourTeam_content">
              <h5>{{ item.title }}</h5>
              <section v-for="(val, ind) in item.content" :key="ind">
                <h6 v-if="val.tit">{{ val.tit }}</h6>
                <p v-if="val.con">{{ val.con }}</p>
                <img v-if="val.url" :src="val.url" :width="val.wid">
              </section>
            </section>
          </section>
        </el-tab-pane>
        <el-tab-pane class="ourTeam_relation" name="ourTeam_relation" label="员工关系和健康" :lazy="lazy">
          <section class="article">
            <h4 class="title">员工关系和健康</h4>
            <section v-for="(item, index) in data5" :key="index" class="ourTeam_content">
              <h5>{{ item.title }}</h5>
              <section v-for="(val, ind) in item.content" :key="ind">
                <h6 v-if="val.tit">{{ val.tit }}</h6>
                <p v-if="val.con">{{ val.con }}</p>
                <img v-if="val.url" :src="val.url" :width="val.wid">
              </section>
            </section>
          </section>
        </el-tab-pane>
      </el-tabs>
    </div>
  </section>
</template>

<script>
  import imageBanner from '../components/ImageBanner.vue'
  export default {
    name: 'ourTeam',
    components: {imageBanner},
    data () {
      return {
        tabPosition: 'left',
        isDisabled: true,
        editableTabsValue : 'ourTeam_team',
        lazy: true,
        data1: [
          {title: null, content: [
            {con: '旋荣致力为员工提供理想的工作环境。公司透过既定的政策及制度聘用人才，并为员工提供定期及多元化的培训及发展机会，培育他们建立理想事业。'},
            {con: '我们提供具竞争力的薪酬及福利，并积极推动各项关爱员工的计划和活动，同时与员工保持定期和坦诚的沟通。'},
            {con: '旋荣的员工是公司最重要的资产，全赖他们的努力和贡献，我们能够为客户提供优质的电力服务。我们重视团队合作，鼓励他们同心为业务贡献专业知识和经验，亦关心员工各方面的需要，鼓励他们参与环保及社会服务工作，积极回馈社会。'},
          ]},
          {title: '（一）缔造理想工作环境', content: [
            {con: '公司总部目前办公地址位于上海外滩板块，距离外滩的直线距离约500米。'},
            {con: '公司的办公地点的选择原则以城市中心或副中心为主，目的是为了方便员工的出行。'},
            {con: '公司的办公环境都是经过精心的装修后才投入使用，确保工作环境的整洁与环保，使我们有个舒心的办公环境。'},
            {con: '公司努力打造一个轻松的工作氛围，平等的同事关系。工作分工明确，每个人知道自己应该干什么，晋升路径明确；我们提倡人与人之间简单的沟通方法，新员工有老同事带，强调同事之间交流不推诿，不扯皮，让大家开开心心的上班。'},
          ]},
          {title: '（二）公司福利', content: [
            {con: '1、培训提升，根据公司需要每年对员工进行专业技术知识的培训，全面提高员工的综合素质；'},
            {con: '2、节日礼品，公司每年的中秋节、端午节、春节为员工发放节日礼品，以示慰问；'},
            {con: '3、健康体检，公司为保证员工身体健康，定期组织员工进行健康体检，根据年龄分布情况，	安排员工每一年或二年体验；'},
            {con: '4、夏季防暑，夏季公司为员工发放防暑用品，根据实际岗位情况发放防暑高温补贴；'},
            {con: '5、生日礼物，公司每年举行2次生日大会，上半年和下半年各一次，并发放相应的生日礼物；'},
            {con: '6、公司团建活动，为提高员工及部门之间的相互协作及加深员工之间的认识及沟通能力，每年根据情况，安排员工团建活动；'},
            {con: '7、激励性奖励，关键岗位员工在公司长期工作，或对优秀员工给予奖金鼓励；'},
            {con: '8、社会保险，公司除给员工缴纳社会保险以外，对特殊岗位和关键岗位购买商业保险；'},
            {con: '9、生育慰问，员工生孩子，公司派代表给予其照顾或者看望。'},
          ]},
          {title: '（三）我们的信念', content: [
            {tit: '企业使命：为电力系统提供全方位的服务。'},
            {con: '唯有热爱电力事业，才能深挖客户的需求，激发自身的潜力，为电力系统的提供各种贴身的服务，创造有价值的产品，才能使我们伴随电力事业的发展而持续成长。'},
            {tit: '为企业客户打造高效的供应链系统和服务。'},
            {con: '唯有充分不断挖掘我们的能力并分享之，为企业客户的供应链赋能，提高全社会供应链的效能，才能使我们伴随客户的成长而持续成长。'},
            {tit: '企业核心价值观：诚信、平等、奋斗、务实、创新。'},
            {con: '诚信：我们只有内心坦荡诚恳，才能言出必行，信守承诺。诚信是我们最重要的无形资产，我们坚持对外以诚信赢得客户，对内以诚信增强企业凝聚力。'},
            {con: '平等：在平时的工作和生活中，要不偏颇的对待别人，不因为别人的财富、容貌、技能、岗位依附或贬低他人，不要去欺负和刁难有求于与你的人，总之：己所不欲勿施于人。需要加强团队合作，共同为公司的目标努力。'},
            {con: '奋斗：唯有艰苦奋斗才能赢得客户的尊重与信赖。奋斗体现在为客户创造价值的任何微小活动中，以及在劳动的准备过程中为充实提高自己而做的努力。我们坚持以奋斗者为本，使奋斗者得到合理的回报。'},
            {con: '务实：尽职尽责，真抓实干，坚持目标导向和结果导向。遵纪守法，正派做人，干净做事，靠专业优势和成果业绩赢得生存发展。不要去做炫耀客户关系，财富等满足个人虚荣心的事情。'},
            {con: '创新：积极进取、勇于开拓，坚持开放与创新。任何先进的技术、产品、解决方案和业务管理，只有转化为商业成功才能产生价值。坚持客户需求导向，并围绕客户需求持续创新。提倡公司制度的创新，公司中的任何人不得贬低和不公正的对待向公司提意见和建议的同事和合作伙伴。'},
            {tit: '企业使命和价值观的作用：'},
            {con: '企业使命和价值观是公司发展的大原则，不仅用于指导我们现有业务的发展，更重要的用于处理我们未经过的事务。我们目前正在不断创新和快速发展中，每当我们遇到之前没有遇见的业务或者事件时，我们都需要审视我们的企业使命和价值观再做决定。'},
          ]},
          {title: '（四）我们的团队', content: [
            {tit: '诚信及廉洁'},
            {con: '内外相应，言行相称。'},
            {con: '根深不惧风吹，行正何惧人言。'},
            {con: '公司自上而下都在饯行诚信和廉洁的工作态度和处事原则，形成良好的工作氛围。'},
            {con: '同时，公司业务的全流程都在助力飞中进行管理，所有工作均留有痕迹，以备后台数据中心进行分析，及时发现问题，解决问题，杜绝隐患。'},
            {tit: '群策群力'},
            {con: '我们强调发挥每一位员工的能力，赋予员工各自司职职务的建议权，在遇到问题时，我们并不以职级的高低来做唯一标准，而是需要讲道理，谁有道理听谁的。'},
            {con: '公司各职级的员工是平等的，只是分工不同。在每个项目成立时，选择项目组成员时，项目组长可以是基层员工，公司总经理或其他高管可以为组员，领受各自的任务，在这个项目的工作安排时，无特殊情况，总经理也需要按照作为项目组长的基层员工的安排。'},
            {con: '企业往往生于谦逊，止于傲慢，无论是对客户，对同事，对工作，我们都要戒骄戒躁。在开展工作之前，需要经过充分的讨论，制定方案后方可执行。'},
            {tit: '员工数据'},
            {tit: '（1）员工年龄分布'},
            {table: {
              header: [{label: '年龄分布', width: 160, align: 'center'}, {label: '',}, {label: '人数比例', width: 120, align: 'center'},],
              data: [
                ['30岁以下', 27.5, '27.5'],
                ['31-40岁', 30, '30'],
                ['41-50岁', 30, '30'],
                ['51岁以上', 12.5, '12.5'],
              ]
            }},
            {tit: '（2）员工学历分布'},
            {table: {
              header: [{label: '学历分布', width: 160, align: 'center'}, {label: '',}, {label: '人数比例', width: 120, align: 'center'},],
              data: [
                ['中专', 15, '15'],
                ['大专', 35, '35'],
                ['本科', 37.5, '37.5'],
                ['硕士及以上', 12.5, '12.5'],
              ]
            }},
            {tit: '（3）员工性别分布'},
            {table: {
              header: [{label: '员工性别分布', width: 160, align: 'center'}, {label: '',}, {label: '人数比例', width: 120, align: 'center'},],
              data: [
                ['男', 55, '55'],
                ['女', 45, '45'],
              ]
            }},
            {tit: '（4）高级管理人员性别分布'},
            {table: {
              header: [{label: '高级管理人员性别分布', width: 160, align: 'center'}, {label: '',}, {label: '人数比例', width: 120, align: 'center'},],
              data: [
                ['男', 17.5, '17.5'],
                ['女', 5, '5'],
              ]
            }},
            {tit: '（5）员工司龄公布'},
            {table: {
              header: [{label: '员工司龄分布', width: 160, align: 'center'}, {label: '',}, {label: '人数比例', width: 120, align: 'center'},],
              data: [
                ['1年以下（包含）', 10, '10'],
                ['1-2年（包含）', 7.5, '7.5'],
                ['2-4年（包含）', 25, '25'],
                ['4-7年（包含）', 42.5, '42.5'],
                ['7年以上', 15, '15'],
              ]
            }},
          ]}
        ],
        data2: [
          {title: null, content: [
            {url: require('../assets/images/our/net.jpg')},
            {url: require('../assets/images/our/electrical-engineer.jpg')},
            {url: require('../assets/images/our/java.jpg')},
            {url: require('../assets/images/our/project-assistant.jpg')},
          ]},
        ],
        data3: [
          {title: '应届生培养计划：', content: [
            {con: '1、培训： 3天的培训，宣导企业文化，学习专业知识，让学员进一步了解工作情况。'},
            {con: '2、培训课程的安排：  企业文化制度，沟通技巧知识，岗位专业技能知识等。'},
            {con: '3、培训考核：培训结束后，针对培训内容进行考核。'},
            {con: '4、正式上岗：考核合格的人员将正式上岗。'},
            {con: '5、师徒培养：建立师徒制度，让应届生更快的适应岗位及工作环境。'},
          ]},
          {title: '岗位晋升计划：', content: [
            {con: '1、岗位晋升的基本原则'},
            {con: 'A.以公平、公正、公开、为基本原则；'},
            {con: 'B.以综合素质和专业技能来综合评定晋升的标准；'},
            {con: '2、岗位晋级分类'},
            {con: 'A.职务任命:根据公司的实际发展及工作需求，任命员工担任某一职务；'},
            {con: 'B.推荐上岗：员工通过内部推荐，从一个岗位转到另一个岗位；'},
            {con: '3、岗位晋级的条件'},
            {con: 'A.员工入职满一年，试用达标，转正合格，无违纪行为；'},
            {con: 'B.被公司或者部门通报，实行一票否决制，一年内不能晋级；'},
            {con: 'C.理论、实操、综合评价考核成绩作为晋级重要依据；'},
            {con: 'D.对员工绩效进行考评，以评选结果作为晋升的依据；'},
            {con: 'E.将员工绩效结果、评选依据记入员工档案中，以备后续进行核实。'},
          ]},
        ],
        data4: [
          {title: null, content: [
            {con: '（1）管理人员的培训:对高层管理人员的培训，以带好队伍、抓好生产经营为培训主题。提高管理人员的决策、指挥、协调、经营等方面的能力。'},
            {con: '（2）专业技术人员的培训：结合公司技术特点及重点，针对专业技术人员进行培训，提高其专业技术能力。'},
            {con: '（3）对新进员工的培训：对新进人员的培训重点是帮助新进员工尽快适应岗位、新环境。对新进员工进行企业规章制度、工作岗位职责、企业文化等内容的培训。'},
            {con: '（4）增加公司其他的培训投入：如增加以下管理技能的培训（言语沟通、时间和压力管理、团队建立、自我意识）。'},
          ]},
        ],
        data5: [
          {title: null, content: [
            {con: '1、员工关爱机制'},
            {con: '关注员工身体健康，每年定期安排一次员工体检项目。'},
            {con: '2、员工沟通机制'},
            {con: '设置一些员工意见箱，满意度调查机制或定期举行员工沟通会，员工反馈自己的疑问和工作中遇到的问题，给予解答。保持与员工沟通，及时了解员工的需求及建议。'},
            {con: '3、康乐活动机制'},
            {con: '每年设置举办员工生日联欢会，聚餐、室外活动等。'},
            {con: '4、员工心里辅导'},
            {con: '对个别员工进行一对一的心里辅导，消除及缓解其心里问题。或举行员工心里讲座，帮助员工增加心里素质，提升归属感，主观幸福感。'},
          ]},
        ],
        imgBannerObj: {
          url: require('../assets/images/aboutus/07-我们的团队.png')
        }
      }
    },
    watch: {
      $route () {
        this.getEditableTabsValue()
      }
    },
    created () {
      this.getEditableTabsValue()
    },
    methods: {
      getEditableTabsValue () {
        switch (this.$route.query.id) {
          case 'team':
            this.editableTabsValue = 'ourTeam_team'
            break
          case 'join':
            this.editableTabsValue = 'ourTeam_join'
            break
          case 'grow':
            this.editableTabsValue = 'ourTeam_grow'
            break
          case 'train':
            this.editableTabsValue = 'ourTeam_train'
            break
          case 'relation':
            this.editableTabsValue = 'ourTeam_relation'
            break
          default:
            this.editableTabsValue = 'ourTeam_team'
        }
      }
    }
  };
</script>

<style scoped>
.ourTeam img{width:100%;}
/* .ourTeam .tab{font-size:1rem;margin:2rem 15%;}
.ourTeam /deep/ .el-tabs__nav{background-color:#fff;}
.ourTeam /deep/ .el-tabs--left .el-tabs__item{padding:.5rem 1rem;text-align:center !important;font-size:1rem;height:auto;line-height:auto;}
.ourTeam /deep/ .el-tabs__item.is-active{background-color:#a2bee7;color:#fff;font-weight:600;}
.ourTeam /deep/ .el-tabs__item.is-disabled{font-size:1.6rem;padding:1.4rem 2rem;background-color:#7895be !important;color:#fff;}
.ourTeam /deep/ .el-tabs--left .el-tabs__active-bar{height:0 !important;} */
.ourTeam .article {font-size:1rem;text-align: left;padding:1rem 2rem;background-color:#fff;min-height: 20rem;}
.ourTeam .article .title{font-size: 2rem; text-align:center;}
.ourTeam .article section.ourTeam_content {border-bottom:1px solid #ccc;padding: .8rem 0;}
.ourTeam .article section.ourTeam_content:last-child {border-bottom:none;}
.ourTeam .article h5{font-weight: 600;font-size: 1.2rem;padding: .2rem;}
.ourTeam .article h6{font-weight: 600;font-size: 1rem;padding: .2rem;}
.ourTeam .article p{padding: .4rem; line-height: 1.6; text-indent: 2em;}
</style>